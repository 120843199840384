<template>
  <div>
    <!-- user info -->
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
            <i class="flaticon2-information text-primary"></i>
          </span>
          <h3 class="card-label">{{ $t('user_details.user_info') }}</h3>
        </div>
        <div class="card-toolbar">
          <router-link
            :to="`/users/${$route.params.id}/edit-user-info`"
            class="btn btn-sm btn-light btn-borderless text-primary font-weight-bold"
            v-if="checkPermission('crm.users.infoedit')"
          >
            <i class="flaticon2-edit text-primary fa-1x"></i> {{ $t('commons.edit') }}
          </router-link>
        </div>
      </div>
      <div
        class="card-body"
        v-if="userDetailsLoading"
      >
        <div class="row col-12">
          <div class="col-4">
            <Skeleton
              :count="14"
              height="20px"
            />
          </div>
          <div class="col-8 font-weight-bolder">
            <Skeleton
              :count="14"
              height="20px"
            />
          </div>
        </div>
      </div>
      <div
        class="card-body"
        v-else
      >
        <template  v-if="isCorporate">
          <div class="row col-12 my-5">
            <div class="col-4">{{ $t('service.commons.corporate') }} </div>
            <div class="col-8 font-weight-bolder">{{ companyName || '-' }}</div>
          </div>
          <div class="row col-12 my-5">
            <div class="col-4">{{ $t('user_details.company_official') }} </div>
            <div class="col-8 font-weight-bolder">{{ userFullName || '-' }}</div>
          </div>
        </template>
        <div class="row col-12 my-5" v-else>
          <div class="col-4">{{ $t('commons.fullname') }}: </div>
          <div class="col-8 font-weight-bolder">{{ userFullName || '-' }}</div>
        </div>
        <div class="row col-12 my-5">
          <div class="col-4">{{ $t('commons.e_mail') }}: </div>
          <div class="col-8 font-weight-bolder">
            {{ user.email || '-' }}
            <i
              class="flaticon2-correct text-success"
              v-if="user.email_verified_at"
              :title="getFormattedDate(user.email_verified_at)"
            ></i>
          </div>
        </div>
        <div class="row col-12">
          <div class="col-4">{{$t('commons.identity_number')}}: </div>
          <div class="col-8 font-weight-bolder">{{ user.identity_number || '-' }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('commons.mobile_phone') }}: </div>
          <div class="col-8 font-weight-bolder">
            {{ user.phone || '-' }}
            <i
              class="flaticon2-correct text-success"
              v-if="user.phone_verified_at"
              :title="getFormattedDate(user.phone_verified_at)"
            ></i>
          </div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.gender') }}: </div>
          <div class="col-8 font-weight-bolder">
            <span class="badge badge-light">{{ user.gender ? $t(`user_details.gender_types.${user.gender}`) : '-' }}</span>
          </div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.birthday') }}: </div>
          <div class="col-8 font-weight-bolder">{{ getUserBirthday }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.level') }}: </div>
          <div class="col-8 font-weight-bolder">
            <span
              class="badge"
              :class="[
                user.level === 10 && 'badge-light',
                user.level === 20 && 'badge-primary',
                user.level === 30 && 'badge-success',
                user.level === 40 && 'badge-dark',
              ]"
            >
              {{ user.level ? $t(`user_details.level_types.${user.level}`) : '-' }}
            </span>
          </div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.account_state') }}: </div>
          <div class="col-8 font-weight-bolder">
            <span
              class="badge"
              :class="[
                user.status === 10 && 'badge-success',
                user.status === 20 && 'badge-warning',
                user.status === 30 && 'badge-light',
                user.status === 40 && 'badge-danger',
              ]"
            >

              {{ user.status ? $t(`user_details.status_types.${user.status}`) : '-' }}
            </span>
          </div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.language') }}: </div>
          <div class="col-8 font-weight-bolder">{{ getUserLocaleStr(user.locale) }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.membership_date') }}: </div>
          <div class="col-8 font-weight-bolder">{{ getFormattedDate(user.created_at) }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.last_activity') }}: </div>
          <div class="col-8 font-weight-bolder">{{ getFormattedDate(user.last_activity_at) }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.user_group') }}: </div>
          <div class="col-8 font-weight-bolder">{{ user.group_name || '-' }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.time_zone') }}: </div>
          <div class="col-8 font-weight-bolder">{{ user.timezone || '-' }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.country') }}: </div>
          <div class="col-8 font-weight-bolder">{{ getCapitalizeText(user.country) }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">{{ $t('user_details.address') }}: </div>
          <div class="col-8 font-weight-bolder">{{ user.address || '-' }}</div>
        </div>
        <div class="row col-12  my-5">
          <div class="col-4">VIP: </div>
          <div class="col-8 font-weight-bolder">
            <div class="radio-inline col-form-label pt-0">
              <label
                class="radio"
                for="no"
              >
                <input
                  type="radio"
                  id="no"
                  :checked="!isVip"
                  @input="updateToVIP"
                />
                <span></span>{{ $t('commons.no') }}
              </label>
              <label
                class="radio"
                for="yes"
              >
                <input
                  type="radio"
                  id="yes"
                  :checked="isVip"
                  @input="updateToVIP"
                />
                <span></span>{{ $t('commons.yes') }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- balances -->
    <div
      class="card card-custom card-stretch mt-10"
      v-if="checkPermission('crm.users.balance.show')"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ $t('user_details.all_balances') }}</h3>
        </div>
        <div class="card-toolbar">
          <button
            @click="hideLowBalances = !hideLowBalances"
            class="btn btn-sm mr-2"
            :class="[hideLowBalances ? 'btn-primary text-white' : 'btn-secondary text-dark']"
            :disabled="balancesLoading"
          >
            <i class="fa fa-check-square text-white fa-1x" v-if="hideLowBalances"></i>
            <i class="fa fa-square text-white fa-1x" v-else></i> 
            {{ $t('commons.hide_zero_value') }}
          </button>
          <button
            @click="getUserBalancesAction($route.params.id)"
            class="btn btn-sm btn-secondary text-primary font-weight-bold"
            :disabled="balancesLoading"
          >
            <i class="flaticon2-refresh text-primary fa-1x"></i> {{ $t('commons.refresh') }}
          </button>
        </div>
      </div>

      <div class="card-body">
        <span class="font-size-h2"><span class="text-muted">{{ $t('user_details.total_balance') }}: </span> {{ getFormattedTotalBalanceByCurrency(totalAmountUSD) }} </span>
      </div>

      <!-- Skeleton -->
      <template v-if="balancesLoading">
        <div class="card-body">
          <div class="d-flex flex-column justify-content-center py-6 px-8 border rounded shadow-sm">
            <div class="row border-bottom">
              <div class="col-12 col-md-6">
                <label class="d-block font-weight-bold">
                  <Skeleton />
                </label>
              </div>
              <div class="col-12 col-md-6 font-weight-bolder text-primary text-right">
                <p>
                  <Skeleton />
                </p>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 col-md-6">
                <label class="d-block">
                  <Skeleton />
                </label>
              </div>
              <div class="col-12 col-md-6 text-right">
                <p>
                  <Skeleton />
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="d-block">
                  <Skeleton />
                </label>
              </div>
              <div class="col-12 col-md-6 text-right">
                <p>
                  <Skeleton />
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <transition-group name="list" tag="p">
          <div
            class="card-body"
            v-for="(balance, idx) in balances"
            :key="`balance-${idx}`"
          >
            <div class="d-flex flex-column justify-content-center py-6 px-8 border rounded shadow-sm">
              <div class="row border-bottom">
                <div class="col-12 col-md-6">
                  <label class="d-block font-weight-bold">{{ balance.currency_name }}</label>
                </div>
                <div class="col-12 col-md-6 font-weight-bolder text-primary text-right">
                  <p>{{ balance.total_formatted }}</p>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12 col-md-6">
                  <label class="d-block">{{ $t('user_details.available_balance') }}</label>
                </div>
                <div class="col-12 col-md-6 text-right">
                  <p>{{ balance.available_formatted }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label class="d-block">{{ $t('user_details.blocked_balance') }}</label>
                </div>
                <div class="col-12 col-md-6 text-right">
                  <p>{{ balance.blocked_formatted }}</p>
                </div>
              </div>
              <div
                class="row"
                v-if="balance.crypto"
              >
                <div class="col-12 col-md-4">
                  <label class="d-block">{{ $t('finance.wallet_address') }}</label>
                </div>
                <div class="col-12 col-md-8 text-right">
                  <p
                    v-for="(wallet, index) in balance.wallets"
                    :key="`wallet-${index}`"
                  >
                    <span>{{ getWalletAddress(wallet).currencyCode }}</span>
                    <span class="ml-5">
                      {{ getWalletAddress(wallet).compactAddress }}
                      <i
                        v-if="getWalletAddress(wallet).address"
                        @click="copyWalletAddress(getWalletAddress(wallet).address)"
                        class="fa fa-copy hover-opacity-70"
                      >
                      </i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </template>
    </div>

    <!-- Market Statistics  -->
    <div
      class="card card-custom mt-10"
      v-if="checkPermission('crm.users.market')"
    >
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
            <i class="flaticon2-information text-primary"></i>
          </span>
          <h3 class="card-label">{{ $t('user_details.market_statistics') }}</h3>
        </div>
        <div class="card-toolbar">
          <div
            class="dropdown dropdown-inline"
            data-toggle="tooltip"
            title="Quick actions"
            data-placement="left"
          >
            <a
              href="#"
              class="btn btn-hover-light-primary btn-sm btn-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div class="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-accent navi-hover navi-bold">
                <li class="navi-header font-weight-bold py-4">
                  <span class="font-size-lg">{{ $t('user_details.choose_market') }}:</span>
                </li>
                <li class="navi-separator mb-3 opacity-70"></li>
                <li
                  class="navi-item"
                  v-for="(marketItem, key) in marketsDropdownList"
                  :key="`market-${key}`"
                  :title="marketItem.label"
                >
                  <a
                    class="navi-link"
                    :class="{'active': selectedMarketId === marketItem.id}"
                    href="javascript:;"
                    @click="selectMarket(marketItem.id)"
                  >
                    <span class="navi-text font-size-lg">{{ marketItem.label }}</span>
                  </a>
                </li>
                <li class="navi-separator mt-3 opacity-70"></li>
                <li
                  class="navi-item"
                  :title="$t('commons.all')"
                >
                  <a
                    class="navi-link"
                    :class="{'active': selectedMarketId === ''}"
                    href="javascript:;"
                    @click="selectMarket('')"
                  >
                    <span class="navi-text font-weight-bold font-size-lg">{{ $t('commons.all') }}</span>
                  </a>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row col-12">
          <h4 class="text-center p-5">{{ $t('commons.no_data_available') }}</h4>
        </div>
      </div>
    </div>

    <!-- balance addition substraction -->
    <div
      class="card card-custom card-stretch mt-10"
      v-if="checkPermission('crm.users.balance.update')"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{ $t('user_details.balance_adjustment') }}</h3>
        </div>
      </div>

      <div class="card-body">
        <ValidationObserver
          v-slot="{handleSubmit}"
          ref="form"
        >
          <form class="form">
            <div
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("commons.currency") }}</label>
                    <validation-provider
                      rules="required"
                      :name="$t('commons.currency')"
                      v-slot="{classes, errors}"
                    >
                      <multiselect
                        v-model="selectedCurrency"
                        :options="currencies"
                        :internal-search="true"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label=""
                        select-label=""
                        selected-label=""
                        track-by="name"
                        label="name"
                        :placeholder="$t('commons.currency')"
                        :class="classes"
                        @input="onChangeCurrency"
                      >
                        <template
                          slot="singleLabel"
                          slot-scope="{ option }"
                        >{{
                          option.name
                        }}</template>
                        <template
                          slot="option"
                          slot-scope="{ option }"
                        >{{
                          option.name
                        }}</template>
                        <template slot="noOptions">{{
                          "List is empty"
                        }}</template>
                      </multiselect>
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("commons.amount") }}</label>
                    <validation-provider
                      rules="required"
                      :name="$t('commons.amount')"
                      v-slot="{classes, errors}"
                    >
                      <currency-input
                        class="form-control"
                        :placeholder="$t('finance.amount')"
                        :class="classes"
                        :precision="decimalPrecision"
                        v-model="balanceAdjustmentForm.amount"
                      />
                      <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("commons.description") }}</label>
                    <textarea
                      class="form-control"
                      :placeholder="$t('commons.description')"
                      v-model="balanceAdjustmentForm.description"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                @click="handleSubmit(() => _handleUpdateBalance('10'))"
              >
                <i class="fa fa-plus"></i> {{ $t('user_details.add_balance') }}
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="handleSubmit(() => _handleUpdateBalance('20'))"
              >
                <i class="fa fa-minus"></i> {{ $t('user_details.substract_balance') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import {Skeleton} from 'vue-loading-skeleton';
import "vue-multiselect/dist/vue-multiselect.min.css";
import {mapActions, mapState} from 'vuex';
import moment from 'moment';

const locales = {
  de: "German(Deutsch)",
  en: "English",
  et: "Estonian",
  fr: "Français",
  me: "Montenegrin",
  nl: "Dutch",
  tr: "Türkçe",
};

export default {
  name: "Overview",
  components: {
    Multiselect,
    Skeleton,
  },
  data() {
    return {
      selectedCurrency: null,
      balanceAdjustmentForm: {
        currency: null,
        amount: null,
        description: null,
        action: '' // 10 = add_balance, 20 = substract_balance
      },
      decimalPrecision: 2,
      selectedMarketId: '',
      hideLowBalances: true
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
      userDetailsLoading: (state) => state.userdetails.userDetailsLoading,
      user: (state) => state.userdetails.userDetails.user,
      userMarketsObject: (state) => state.userdetails.userDetails.markets,
      userDetailsCurrencies: (state) => state.userdetails.userDetails.currencies,
      totalAmountUSD: (state) => state.userdetails.userDetails.totalAmountUSD,
      balancesLoading: (state) => state.userdetails.isLoading,
      originalBalancesData: (state) => state.userdetails.balances,
    }),
    isCorporate() {
      return this.user?.type ? `${this.user.type}` === "20" : false;
    },
    userFullName() {
      return `${this.user.name} ${this.user.surname}`;
    },
    companyName() {
      const companyName = this.user?.company || '-';
      return companyName;
    },
    marketsDropdownList() {
      const entries = Object.entries(this.userMarketsObject);
      if (entries.length > 0) return entries.map(([key, value]) => ({id: key, label: value}))
      return [];
    },
    currencies() {
      if (this.userDetailsCurrencies.length > 0) {
        return this.userDetailsCurrencies.map(item => ({...item.currency}))
      }
      return []
    },
    isVip() {
      return this.user?.type?.toString() === "60" || false;
    },
    getUserBirthday() {
      if (this.user.birthday) {
        const split = this.user.birthday.split('T');
        return `${split[0]} ${split[1].substring(0, 8)}`
      }
      return '-';
    },
    balances() {
      if(this.hideLowBalances) {
        return this.originalBalancesData.filter(item => parseFloat(item?.available) > 0);
      }
      return this.originalBalancesData;
    },
  },
  methods: {
    ...mapActions('userdetails', ["getUserBalancesAction"]),
    checkPermission(permission) {
      return this.permissions?.some(val => String(val.name) === String(permission));
    },
    onChangeCurrency() {
      this.decimalPrecision = this.selectedCurrency?.decimal || 2
    },
    _handleUpdateBalance(action) {
      this.balanceAdjustmentForm = {
        ...this.balanceAdjustmentForm,
        action,
        currency: this.selectedCurrency?.code || '',
      }
      this.$store.dispatch('userdetails/updateBalanceAction', {
        id: this.$route.params.id,
        formData: this.balanceAdjustmentForm
      }).then(() => {
        this.balanceAdjustmentForm = {
          currency: null,
          amount: null,
          description: null,
          action: ''
        },
          this.$nextTick(() => {
            this.$refs.form.reset();
          });
      })
    },
    getStrValue(str) {
      if (str) return str;
      return '';
    },
    getUserLocaleStr(locale) {
      const localesEntries = Object.keys(locales);
      if (localesEntries.map(key => key).includes(locale)) return locales[locale];
      return "-";
    },
    getFormattedDate(dt) {
      if (dt) return moment(dt).format('DD/MM/YYYY H:m:s')
      return '-'
    },
    getFormattedTotalBalanceByCurrency(value, currency = 'USD') {
      if (!value) {
        return '$0';
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
    selectMarket(marketId) {
      this.selectedMarketId = marketId;
    },
    getWalletAddress(wallet) {
      if (wallet?.address) {
        const start = wallet.address.substring(0, 5);
        const middle = ". . . . . .";
        const end = wallet.address.substring(wallet.address?.length-5);
        return {compactAddress: start + middle + end, address: wallet.address, currency: wallet.currency, currencyCode: wallet.currency_code};
      }
      return {compactAddress: "-", address: ''};
    },
    copyWalletAddress(copyText) {
      navigator.clipboard.writeText(copyText);
      this.$toast.info(this.$t("copied_to_clipboard"));
    },
    getCapitalizeText(str) {
      return str ? str[0].toUpperCase() + str.slice(1) : "-";
    },
    updateToVIP() {
      this.$store.dispatch('userdetails/updateToVIPAction', this.user.id)
    }
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.users.show")) {
      this.$router.push("/no-permission");
      return;
    }
    this.getUserBalancesAction(this.$route.params.id)
  }
};
</script>
<style lang="scss" scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
